<template>
  <div class="regulations-container">
    <div class="regulations-container-bread">
      共{{ totalPage }}页 | {{ total }}条数据
    </div>
    <div class="regulations-container-pic">
      <img
        src="https://image.bookgo.com.cn/webculture/jm/partyBuilding/partyBuilding-pic.png"
        alt=""
      />
    </div>
    <commonList :list="regulationList" />
    <!-- 分页组件 -->
    <pagination
      :total="total"
      :page.sync="page"
      :limit.sync="limit"
      @pagination="getList"
      v-if="regulationList.length > 0"
    />
  </div>
</template>

<script>
import commonList from "../components/commonList";
import Pagination from "@/components/Pagination";
import { list } from "../../api/article";
export default {
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      id: "",
      aid: process.env.VUE_APP_AID,
      regulationList: [],
    };
  },
  components: { commonList, Pagination },
  computed: {
    totalPage() {
      return Math.ceil(this.total / this.limit);
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      list({ aid: this.aid, app_type: 1, cat_id: this.id,page:this.page,page_size:this.limit }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.total = res.data.count;
          this.regulationList = res.data.list;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/regulations.scss";
</style>
